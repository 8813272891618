import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getData } from '../../utils/utilityFunctions';

const AutoHeaderContainer = styled.div`
  @import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);

  font-family: 'Poppins', sans-serif;
  color: #000;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 1rem;
  max-width: 600px;
  margin: 0 auto 1rem;
  min-height: 222px;

  img {
    width: 130px;
    margin: 0 10px 0 0;
  }
  div {
    flex: 1;
    padding: 1rem 0;
    max-width: 620px;
  }
  h1 {
    font-weight: 700;
    font-size: clamp(32px, 3vw, 38px);
    line-height: 22px;
    color: #2cda94;
  }
  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.3;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
  }
  @media screen and (max-width: 800px) {
    padding: 1rem;

    img {
      width: 100px;
      margin: 0 0.5rem 0 0;
    }
    div {
      padding: 1rem 0;
    }
    h1 {
      font-size: clamp(30px, 3vw, 30px);
      line-height: 1.5;
    }
    h3 {
      font-size: 18px;
    }
    p {
      font-size: 14px;
    }
  }
`;

const AutoTypHeader = ({ params }) => {
  return (
    <AutoHeaderContainer id="MaTarget">
      <img
        src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/autoheadlinev3-checkmark.gif"
        alt="Animated Checkmark"
      />
      <div className="autoheadline_headers">
        <h1>Success!</h1>
        <h3>
          <span className="dynamicName">{getData('first_name')} </span> here are
          your specialized Auto Insurance Offers in{' '}
          <span className="dynamicState">
            {getData('state') || 'your area'}
          </span>
        </h3>
        <p>Click 3+ Below to Compare.</p>
      </div>
    </AutoHeaderContainer>
  );
};

export default AutoTypHeader;
