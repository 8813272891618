import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getData } from '../../utils/utilityFunctions';

const CountDownHeader = styled.div`
#auto-headline-test {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 730px;
  margin: 0 auto;

}

#timer{
  color: orange;
}

#auto-headline-test img {
  width: 150px;
}

#auto-headline-test .auto-headline-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  width: 55%;
}

#auto-headline-test .auto-headline-title h1 {
  color: #3946f6;
  margin-bottom: 0;
  font-size: 26px;
  margin-top: 0;
}

#auto-headline-test .auto-headline-title p {
  color: #333333;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
}


@media (max-width: 801px) {
  #auto-headline-test {
    flex-direction: column;
    text-align: center;
  }

#auto-headline-test img {
  width: 80px;
}
#auto-headline-test .auto-headline-title {
  width: calc(100% - 20px);
}
`;

const CustomCountdownHeader = ({ params }) => {
  const [timer, setTimer] = useState(120);
  const [timeLeft, setTimeLeft] = useState();

  const headerTest = version => {
    const headers = {
      '0': 'Success!',
      '2': "Thanks! You're all Set!",
      '3': 'Congrats!',
      '4': 'Thank you!',
    };
    if (headers[version]) {
      return headers[version];
    }
    return headers['0'];
  };

  const medOrHealthTY = loanPurpose => {
    const header = {
      health: 'here are your specialized Health Insurance',
      med: 'here are your Medicare Advantage Plan',
    };

    if (header[loanPurpose]) return header[loanPurpose];

    return header.health;
  };

  useEffect(() => {
    const windowCheck = typeof window !== 'undefined';
    const isDesktop = window.innerWidth > 801;
    if (windowCheck && isDesktop) {
      const countDown = setInterval(() => {
        const minutes = Math.floor(timer / 60);
        let seconds = timer % 60;
        if (seconds < 10) {
          seconds = `0${seconds}`;
        }
        setTimer(timer - 1);
        setTimeLeft(`${minutes}:${seconds}`);

        if (timer <= 0) {
          clearInterval(countDown);
          setTimer(0);
        }
      }, 1000);

      return () => clearInterval(countDown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);
  return (
    <CountDownHeader id="MaTarget">
      <div id="auto-headline-test">
        <img
          className="checkMark"
          src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/animation_200_checkmark.gif"
          alt="Checkmark"
        />
        <div className="auto-headline-title">
          <h1>{headerTest(params?.version)}</h1>
          <p>
            {getData('first_name')}, {medOrHealthTY(params?.loan_purpose)}{' '}
            Offers for {getData('zipcode')}
          </p>
          <p>Click 3+ Below to Compare.</p>
          <p>
            You have <span id="timer">{timeLeft}</span> minutes left to claim
            these great offers!
          </p>
        </div>
      </div>
    </CountDownHeader>
  );
};

export default CustomCountdownHeader;
