import React from 'react';
import styled, { keyframes } from 'styled-components';
import { getData } from '../../utils/utilityFunctions';
import { useLayoutQueries } from '../../utils/queries';

const pulse = keyframes`
0% {transform: scale(1);}
50% {transform: scale(1.3);}
100% {transform: scale(1);}
`;

const ArrowContainer = styled.div`
  margin-bottom: 0;
  animation: ${pulse} 2s infinite;
  i {
    border: solid black;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 8px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    font-size: 24px;
  }

  &.lastArrow {
    margin-bottom: 10px;
  }
`;

const TyPageHeadlineContainer = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=News+Cycle&display=swap');
  background: #3279c7;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 5px;
  position: relative;
  font-family: 'News Cycle', sans-serif !important;
  margin-bottom: 15px;
`;

const Headline = styled.h1`
  margin-top: 10px;
  color: #b1e3b3;
  text-shadow: rgb(51, 51, 51) 2px 2px;
  margin-bottom: 5px;

  font-family: 'News Cycle', sans-serif !important;
`;

const SubHead = styled.h3`
  text-align: center;
  @media (max-width: 801px) {
    font-size: 15px;
  }
  color: #fff;
  text-shadow: rgb(51, 51, 51) 2px 2px;

  font-family: 'News Cycle', sans-serif !important;
`;

const Ptag = styled.p`
  margin-bottom: 15px;
  color: #fff;
  text-shadow: rgb(51, 51, 51) 2px 2px;

  font-family: 'News Cycle', sans-serif !important;

  @media (max-width: 801px) {
    font-size: 14px;
  }
`;

const ThankYouHeaderLogoContainer = styled.div`
  position: absolute;
  left: 30px;
  max-width: 250px;
  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 500px) {
    left: 10px;
    max-width: 100px;
    top: 15px;
  }
`;

const HealthAndMedHeader = ({ config, params }) => {
  const { siteImages } = useLayoutQueries();

  const allImages = siteImages.edges;

  const originalSiteLogo = allImages.find(image => {
    return image.node.fluid.originalName === 'logo.png';
  });

  const setSubHead = () => {
    const text =
      params?.loan_purpose === 'health'
        ? 'Your Health Insurance Offers are Below.'
        : 'Your Medicare Advantage Plan Offers are Below.';

    const firstName = getData('first_name') || '';
    const headline = firstName ? `${firstName}, ${text}` : text;

    return headline;
  };

  return (
    <TyPageHeadlineContainer id="tyPageHeadlineContainer">
      <ThankYouHeaderLogoContainer>
        <img src={originalSiteLogo.node.fluid.src} alt={config?.surveyName} />
      </ThankYouHeaderLogoContainer>
      <Headline>Success!</Headline>
      <SubHead>{setSubHead()}</SubHead>
      <Ptag>Compare your unique results below.</Ptag>
    </TyPageHeadlineContainer>
  );
};

export default HealthAndMedHeader;
